// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';

import NodeBody from '~plugins/prismic/components/NodeBody';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import useAllNodeBodyComponents from '~hooks/useAllNodeBodyComponents';
import type { PrismicHomePage } from '~schema';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicHomePage>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginTop: 51,
    [theme.breakpoints.up('lg')]: {
      marginTop: 89,
    },
  },
  bodySection: {},
});

const nodeBodyComponents = useAllNodeBodyComponents();

const HomePage = ({ classes, node }: Props) => {
  return (
    <div className={classes.root}>
      <NodeBody
        node={node}
        components={nodeBodyComponents}
        className={classes.bodySection}
      />
    </div>
  );
};

export default compose(
  withNodePage<PrismicHomePage, *>({
    getNode: data => data?.prismicHomePage,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(HomePage);

export const query = graphql`
  query HomePageQuery($prismicId: ID) {
    prismicHomePage(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        body {
          ... on Node {
            id
          }
          ... on PrismicSliceType {
            slice_type
          }
          ... on PrismicHomePageBodyHeader {
            id
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_height
            }
          }
          ... on PrismicHomePageBodyPlainText {
            id
            primary {
              slice_title {
                text
                html
              }
              slice_text {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_text_alignment
              slice_text_justification
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicHomePageBodyContentGrid {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              items_per_row
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyMediaGrid {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_media_width
              item_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_video {
                link_type
                target
                url
              }
            }
          }
          ... on PrismicHomePageBodyQuote {
            id
            slice_type
            primary {
              slice_headline {
                text
                html
              }
              slice_quote {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicHomePageBodyParallaxBanner {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_background_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_text_alignment
              slice_width
            }
          }
          ... on PrismicHomePageBodyHighlights {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              items_per_row
            }
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_image {
                alt
                url
                fluid(maxWidth: 100) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyFaqs {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_question {
                text
                html
              }
              item_answer {
                text
                html
              }
            }
          }
          ... on PrismicHomePageBodyVideo {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_video {
                link_type
                target
                url
              }
              slice_video_image_caption {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicHomePageBodySingleImage {
            id
            slice_type
            primary {
              slice_caption {
                text
                html
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_width
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicHomePageBodyRearrangeableBlock {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_image_position
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              slice_width
            }
          }
          ... on PrismicHomePageBodyEvent {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_link_label {
                text
              }
              slice_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              slice_image {
                alt
                url
                fluid(maxWidth: 700) {
                  ...GatsbyPrismicImageFluid
                }
              }
              slice_date_picker
              slice_padding_top
              slice_padding_bottom
            }
          }
          ... on PrismicHomePageBodyCardsSlider {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
            }
            items {
              item_title {
                text
                html
              }
              item_subtitle {
                text
                html
              }
              item_description {
                text
                html
              }
              item_image {
                alt
                url
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyRates {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              slice_display
            }
            items {
              item_price {
                text
                html
              }
              item_title {
                text
                html
              }
              item_subtitle {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_image {
                alt
                url
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomePageBodyLocationMap {
            id
            slice_type
            primary {
              slice_title {
                text
                html
              }
              slice_description {
                text
                html
              }
              store_link {
                document {
                  ... on PrismicStore {
                    id
                    data {
                      name {
                        text
                      }
                      store_location {
                        text
                        html
                      }
                      store_hours {
                        text
                        html
                      }
                      store_email {
                        text
                      }
                      store_map_link {
                        url
                      }
                    }
                  }
                }
              }
              slice_background_color
              slice_padding_top
              slice_padding_bottom
              slice_width
              show_additional_info
            }
          }
          ... on PrismicHomePageBodyFullwidthCarousel {
            id
            slice_type
            items {
              item_title {
                text
                html
              }
              item_description {
                text
                html
              }
              item_link_label {
                text
              }
              item_link {
                id
                uid
                type
                link_type
                isBroken
                target
                url
              }
              item_background_image {
                alt
                url
                fluid(maxWidth: 1800) {
                  ...GatsbyPrismicImageFluid
                }
              }
              item_text_alignment
            }
          }
        }
      }
    }
  }
`;
